import Backbone from 'backbone'
import { post } from '@rails/request.js'

const initializeEuQuestionnaire = (options) => {
  const EuQuestionnaire = class extends Backbone.View.extend({
    events: {
      'submit #eu-quiz': 'continueButtonClicked',
      'click .question-group input': 'resetMessages'
    }
  }) {
    constructor (options) {
      super(options)
      this.options = options

      this.assessmentMade = false
      this.conflictMessageShown = false
      this.submitted = false

      this.$assessmentMessage = this.$el.find('#assessment-message')
      this.$conflictMessage = this.$el.find('#conflict-message')
      this.$form = this.$el.find('#eu-quiz')
    }

    continueButtonClicked (event) {
      event.preventDefault()

      if (this.$form[0].checkValidity()) {
        if (this.canShowConflictMessage()) {
          this.conflictMessageShown = true
          this.showConflictMessage()
        }

        if (!this.submitted) {
          if (this.assessmentMade) {
            this.submitted = true
            this.submitForm()
          } else {
            this.checkForm()
          }
        }
      }
    }

    async checkForm () {
      const formData = new FormData(this.$form[0])
      const result = await post(this.options.checkEndpoint, {
        body: formData
      })

      const json = await result.response.json()
      this.makeAssessment(json)
    }

    async submitForm () {
      const formData = new FormData(this.$form[0])
      await post(this.$form.attr('action'), {
        body: formData
      })

      this.redirectToNextStep()
    }

    canShowConflictMessage () {
      return this.conflictMessageShown === false && this.hasConflictingAnswers() === true
    }

    hasConflictingAnswers () {
      return this.$form.find('input[type="radio"]:checked').filter('[data-conflicting="true"]').length === 1
    }

    showConflictMessage () {
      this.$conflictMessage.removeClass('hidden')
    }

    redirectToNextStep () {
      window.location.href = this.options.nextStep
    }

    resetMessages () {
      this.$assessmentMessage.addClass('hidden')
      this.assessmentMade = false
      this.$conflictMessage.addClass('hidden')
      this.conflictMessageShown = false
    }

    makeAssessment (data) {
      this.assessmentMade = true
      if (data.assessment === 'unsophisticated') {
        this.$assessmentMessage.removeClass('hidden')
      } else {
        this.submitForm()
      }
    }
  }
  return new EuQuestionnaire(options)
}

export default initializeEuQuestionnaire
