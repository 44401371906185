/* global $ */
import 'tifosy'

import 'components/tifosy_panel'

import initializeEuQuestionnaire from 'certifications/eu_questionnaire'
import tifosyCloudinary from 'tifosy_cloudinary'

document.addEventListener('turbo:load', (event) => {
  tifosyCloudinary.responsive()

  if ($('#new-questionnaire-content').length) {
    const data = $('#questionnaire-data').data()
    data.el = '#new-questionnaire-content'

    initializeEuQuestionnaire(data)
  }
})
