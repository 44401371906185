/* global $ */
$('.panel-heading').click((event) => {
  $('.panel-collapse.show').collapse('hide')

  if ($(event.currentTarget).hasClass('selected')) {
    $('.panel-heading.selected').removeClass('selected')
  } else {
    $('.panel-heading.selected').removeClass('selected')
    $(event.currentTarget).addClass('selected')
  }
})

$('.panel-tab-heading a, .rights-panel-heading a').click((event) => {
  if (!$(event.currentTarget).parent().hasClass('selected')) {
    $('.panel-tab-heading.selected, .rights-panel-heading.selected').removeClass('selected')
    $(event.currentTarget).parent().addClass('selected')
  }
})
